<template>
  <section class="my-16" style="background-image: url(/bg1.png); background-position: right top;">
      <v-container class="text-center">
        <h6
          style="font-size: 1.25rem; color: rgba(252, 75, 107); letter-spacing: 0.1em;}"
        >
          WE GOT ALL YOU NEED
        </h6>
        <h1 style="font-size: 2.5rem">Master Vue.js and the Ecosystem</h1>
        <v-row justify="center" class="mt-5">
          <div class="d-flex flex-row">
            <span style="font-size: 2rem; font-weight: 500">606</span>
            <span
              style="
                font-size: 1.25rem;
                color: rgba(100, 107, 149);
                line-height: 2.7;
              "
              >Video Lessons</span
            >
          </div>
          <v-divider vertical class="mx-5"></v-divider>
          <div class="d-flex flex-row">
            <span style="font-size: 2rem; font-weight: 500">50+</span>
            <span
              style="
                font-size: 1.25rem;
                color: rgba(100, 107, 149);
                line-height: 2.7;
              "
              >Hours</span
            >
          </div>
          <v-divider vertical class="mx-5"></v-divider>
          <div class="d-flex flex-row">
            <span style="font-size: 2rem; font-weight: 500">30+</span>
            <span
              style="
                font-size: 1.25rem;
                color: rgba(100, 107, 149);
                line-height: 2.7;
              "
              >Courses</span
            >
          </div>
        </v-row>
      </v-container>
    <section style="background-image: url(/bg2.png); background-position: left center;">
      <v-container>
        <v-row justify="center" class="my-10">
          <v-col cols="2" v-for="(card, index) in cards" :key="card">
            <v-card
              width="170"
              height="180"
              class="text-center py-10 pb-5"
              style="
                position: relative;
                box-shadow: 20px 20px 20px 20px rgba(59, 130, 246, 0.07);
              "
            >
              <v-img
                :src="'/card' + (index + 1) + '.png'"
                width="72"
                class="mx-auto"
              ></v-img>
              <div style="width: 100%; position: absolute; bottom: 5%">
                <p class="pt-5">{{ card }}</p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
      <section style="background-image: url(/bg3.png); background-position: right bottom;">
      <v-container>
        <h1 style="font-size: 2.5rem" class="my-16 text-center">
          Be the Developer you Deserve to Be
        </h1>
        <v-row>
          <v-col cols="6" v-for="item in items" :key="item">
            <v-list three-line>
              <template>
                <v-list-item :key="item.title">
                  <v-img
                    max-width="48"
                    :src="item.avatar"
                    class="mr-5 mt-n4"
                  ></v-img>

                  <v-list-item-content>
                    <v-list-item-title
                      style="font-size: 1.25rem; font-weight: 500"
                      >{{ item.title }}</v-list-item-title
                    >
                    <v-list-item-subtitle
                      style="
                        color: rgba(103, 127, 155);
                        line-height: 1.5;
                        font-size: 0.55em;
                      "
                    >
                      {{ item.subtitle }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-col>
        </v-row>
      </v-container>
      </section>
    </section>
  </section>
</template>
<script>
export default {
  data() {
    return {
      cards: [
        "Vue.js",
        "Nuxt.js",
        "Vuex",
        "Testing & Jest",
        "GraphQL",
        "JavaScript",
        "Storybook",
        "Vue Styleguidist",
        "Firebase Suite",
        "Typescript",
      ],
      items: [
        {
          avatar: "/avatar-1.png",
          title: "Build Complete Websites",
          subtitle:
            "Complete Single Page Applications or Server Side Rendered - you name it! Build any sort of web application using Vue and friends: vue cli, vue router, single-file components, Nuxt, and more.",
        },
        {
          avatar: "/avatar-2.png",
          title: "Advanced User Experience",
          subtitle:
            "Learn how to develop multilingual websites (i18n), fancy form wizards, advanced form validation, and even websites that update live as the database changes.",
        },
        {
          avatar: "/avatar-3.png",
          title: "Reusable Code",
          subtitle:
            "Don't sit around wondering how your component and domain logic is re-used throughout your application(s). Our team of experts will teach you all the techniques: Functional Components, Slots, and the brand new Composition API (soon).",
        },
        {
          avatar: "/avatar-4.png",
          title: "Document your code!!",
          subtitle:
            "What is good code without good documentation? We teach you how to develop top-notch live documentation and guides for your components using Storybook and Vue Styleguidist.",
        },
        {
          avatar: "/avatar-5.png",
          title: "Enterprise Grade Applications",
          subtitle:
            "Future proof the codebase with linting, testing, and accessibility in mind! Learn the techniques and the best practices from Vue School!",
        },
      ],
    };
  },
};
</script>